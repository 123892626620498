@import '../../global/style-guide';

#widgetContainer {
	--container-side-padding: 0;
	--item-count: 6;
	--item-column-gap: 15px;
	--parent-container-gap: #{$responsiveSideGutter * 4}; // #lpContainer & .hpWrapper side margins incl $gutterMultiplier
	--widget-divider-gap: 30px;
	--content-width: 100vw;
	--item-max-width: 220px;
	--item-width: 100%;
	box-sizing: border-box;
	display: flex;
	margin: 50px auto 35px;
	max-width: 100%;

	&.widgetContainer--notFull {
		width: fit-content;
	}

	@media (max-width: 1060px) {
		width: fit-content;
	}

	@media (max-width: 1024px) {
		--widget-divider-gap: 25px;
	}

	@media (max-width: 960px) {
		--parent-container-gap: 0px; // px required as it messes up the calc without unit
		--content-width: 960px;
		padding-bottom: 40px;
		padding-top: 30px;
	}
}

.certonaWidgetContainer {
	position: relative;

	&__header {
		align-items: center;
		display: flex;
		left: 0;
		position: absolute; // To prevent content from being pushed to the right when there's only 1 Recently Viewed item
		top: 0;
		white-space: nowrap;
	}

	&__headerTitle {
		display: inline-block;
		font: 400 2rem var(--fontHeading);
		margin-bottom: 0;

		@media (max-width: 1330px) {
			font-size: 1.6rem;
		}

		@media (max-width: 1120px) {
			font-size: 1.5rem;
		}

		@media (max-width: 980px) {
			font-size: 1.4rem;
		}
	}

	&__delimiter {
		font-size: 1.6rem;
		margin: 0 5px;

		@media (max-width: 1024px) {
			font-size: 1.5rem;
		}
	}

	&__viewAllBtn {
		font-size: 1.5rem;
		line-height: normal;
		
		@media (max-width: 1380px) {
			font-size: 1.3rem;
		}

		@media (max-width: 1330px) {
			font-size: 1.1rem;
		}

		@media (max-width: 1120px) {
			font-size: 1rem;
		}
	}

	&__list {
		display: flex;
		padding-top: 39px;

		&:has(.unveil) {
			padding-top: 10px;
		}
	}

	&__item {
		max-width: var(--item-max-width);
		width: var(--item-width);

		&:not(:last-child) {
			padding-right: var(--item-column-gap);
		}
	}

	.productLink {
		display: block;
		font-size: 1.2rem;
		outline-offset: 2px;
		text-decoration: none;

		@media (max-width: 960px) {
			font-size: 1.1rem;
		}
	}

	.productImage {
		border-radius: 5px;
		display: block;
		max-width: var(--item-max-width);
		width: var(--item-width);
	}

	.productName {
		box-orient: vertical;
		display: box;
		-webkit-line-clamp: 2;
		line-height: 1.4;
		margin-top: 15px;
		overflow: hidden;
	}

	.productPrice {
		font-weight: 500;
		margin-top: 5px;
	}

	.salePrice {
		color: var(--darkRed);
	}

	.saveAmount {
		font-weight: normal;
		white-space: nowrap;
	}
}

#recentlyViewedWidgetContainer + #justForYouWidgetContainer {
	margin-left: calc(var(--widget-divider-gap) * 2);
	position: relative;

	&::before {
		background: #ccc;
		content: '';
		display: block;
		height: calc(100% + 15px);
		left: calc(var(--widget-divider-gap) * -1);
		position: absolute;
		width: 1px;
	}
}
